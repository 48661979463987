import firebase from "firebase";
import { User, UserManager, WebStorageStateStore } from "oidc-client";
import { REDIRECT_PATHNAME } from "src/common/constants";
import { auth } from "src/data/firebase.config";
import { RoutePath } from "src/data/RoutePath";
import LocalStorageService from "./LocalStorageService";

export interface ILoginService {
  tryLogin: () => Promise<User | undefined>;
  handleLogin: (onRedirect: () => void) => Promise<User>;
}

class LoginService implements ILoginService {
  private _webStorageStateStore = new WebStorageStateStore({
    store: window.localStorage,
  });

  private _userManager = new UserManager({
    redirect_uri: `${window.location.origin}${RoutePath.LoginCallback}/`,
    silent_redirect_uri: `${window.location.origin}${RoutePath.LoginCallback}/`,
    authority: "https://sso.nedair.nl",
    client_id: "unit-forms",
    scope: "openid profile offline_access email",
    automaticSilentRenew: true,
    response_type: "code",
    userStore: this._webStorageStateStore,
  });

  /**
   * Gets the custom firebase token using a firebase function.
   * @param accessToken access token provided by authority.
   */
  private async getCustomFirebaseToken(accessToken: string): Promise<string> {
    const getCustomFirebaseToken = firebase
      .functions()
      .httpsCallable("getCustomFirebaseToken");

    const { data } = await getCustomFirebaseToken({
      accessToken,
    });

    return data;
  }

  private async handleRedirectToSSO() {
    const { pathname } = window.location;

    // Store current path in localstorage so we can redirect to
    // this path after successfully loging in.
    LocalStorageService.set(REDIRECT_PATHNAME, pathname);

    await this._userManager.signinRedirect();
  }

  private async signInToFirebase(customFirebaseToken: string, email: string) {
    try {
      const firebaseUserCredentials = await auth.signInWithCustomToken(
        customFirebaseToken
      );

      const { user } = firebaseUserCredentials;

      if (!user) throw Error("No user returned from firebase.");

      if (!user.email) {
        await user.updateEmail(email);
      }
    } catch (e) {
      throw Error("Firebase failed to sign in user.");
    }
  }

  private async login(ssoUser: User) {
    const customToken = await this.getCustomFirebaseToken(ssoUser.access_token);

    // Todo: this went wrong three times, no email or name on profile.
    // find out how this had happened. And maybe this comment
    // will guide you when someone complains about not being able
    // This might be related to wrong time set locally, and token not valid. ~Tim~ (╯°□°）╯︵ ┻━┻
    // to see the application and getting the thrown error...
    if (!customToken || !ssoUser.profile.email || !ssoUser.profile.name)
      throw Error("Could not login.");

    await this.signInToFirebase(customToken, ssoUser.profile.email);
  }

  public async tryLogin(): Promise<User | undefined> {
    const ssoUser = await this._userManager.getUser();

    // If no user in local storage, we redirect to login instantly.
    // If there is a user we check if the token is expired using the date.
    // If it's expired we redirect else we progress the login flow.
    if (!ssoUser) {
      await this.handleRedirectToSSO();
    } else {
      const expiryDate = new Date(ssoUser.expires_at * 1000);

      if (new Date() > expiryDate) {
        await this.handleRedirectToSSO();
      } else {
        await this.login(ssoUser);

        return ssoUser;
      }
    }
  }

  public async handleLogin(
    onRedirect: (pathName: string) => void
  ): Promise<User> {
    const ssoUser = await this._userManager.signinRedirectCallback();

    await this.login(ssoUser);

    // Store user in localStorage so user does not have to login in again.
    await this._userManager.storeUser(ssoUser);

    // Call redirect callback so user is navigated to original path.
    onRedirect(LocalStorageService.get(REDIRECT_PATHNAME) ?? "");

    return ssoUser;
  }
}

export default new LoginService();
