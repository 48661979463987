export interface IPaletteColor {
  light?: string;
  main: string;
  dark?: string;
  contrastText?: string;
}

export interface IPalleteColors {
  primary: IPaletteColor;
  secondary: IPaletteColor;
  error: IPaletteColor;
  warning: IPaletteColor;
  info: IPaletteColor;
  success: IPaletteColor;
  card: IPaletteColor;
}

export type ColorType =
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "info"
  | "success"
  | "card";

export enum ColorEnum {
  primary = "primary",
  secondary = "secondary",
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
  card = "card",
}

export interface IPallete extends IPalleteColors {
  text: {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
  };
  background: {
    paper: string;
    default: string;
  };
}

export interface ITheme {
  pallete: IPallete;
}

export const lightTheme: ITheme = {
  pallete: {
    primary: {
      main: "#3f51b5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#dc004e",
    },
    error: {
      main: "#FF7043",
      contrastText: "#fff",
    },
    warning: {
      main: "#ff9800",
      contrastText: "#fff",
    },
    info: {
      main: "#2196f3",
    },
    success: {
      main: "#66BB6A",
      contrastText: "#fff",
    },
    card: {
      main: "#fff",
      contrastText: "#000",
    },
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    background: { paper: "#E8EDF5", default: "#fff" },
  },
};
