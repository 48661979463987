import styled, { createGlobalStyle } from "styled-components/macro";
import { normalize } from "./normalize";
import { ITheme } from "../../../data/theme/theme";

export const GlobalStyle = createGlobalStyle<{
  gridSize: number;
  theme: ITheme;
}>`
  ${normalize}

  html,
  body {
    // Grid size is defined using the font-size
    font-size: 16px;
    height: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: Roboto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    color: black;
    overflow-x: hidden;
    background-color: ${(props) => props.theme.pallete.background.default};
  }

  * {
    box-sizing: border-box;
  }

  svg {
    fill: currentColor;
    min-width: 24px;
    min-height: 24px;
  }

  #root{
    height: 100%;
  }

  // Removes emulator warning.
  .firebase-emulator-warning{
    display: none;
  }
`;

export const PageContainerElement = styled.div`
  min-height: 100vh;
  width: 100%;
  transition: 0.3s ease-in-out;
  position: absolute;
`;
