import { createContext, useState } from "react";
import { LoginStore } from "src/stores/LoginStore";

export const LoginContext = createContext({
  loginStore: {} as LoginStore,
});

export const LoginManager: React.FC = ({ children }) => {
  const [loginStore] = useState(() => new LoginStore());

  return (
    <LoginContext.Provider value={{ loginStore: loginStore }}>
      {children}
    </LoginContext.Provider>
  );
};
