import { makeAutoObservable } from "mobx";
import { User } from "oidc-client";
import LoginService from "../services/LoginService";

export class LoginStore {
  private _user: User | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public async handleLogin(onRedirect: (pathName: string) => void) {
    this._user = await LoginService.handleLogin(onRedirect);
  }

  public async tryLogin() {
    this._user = await LoginService.tryLogin();
  }

  public get user() {
    return this._user;
  }
}
