type StorageDefaultValuesType = {
  [name in StorageKey]: any;
};

export enum StorageKey {
  TrackingConsent = "trackingConsent",
  Locale = "locale"
}

export const StorageDefaultValues: StorageDefaultValuesType = {
  [StorageKey.TrackingConsent]: undefined,
  [StorageKey.Locale]: undefined
};
