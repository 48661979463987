import React, { useCallback, useEffect, useState } from "react";
import { IntlProvider, MessageDescriptor } from "react-intl";
import { SplashScreen } from "src/components/general/SplashScreen";
import { StorageKey } from "src/services/data";
import LocalStorageService from "src/services/LocalStorageService";
import { messages } from "./data";
import Locale, { defaultLocale } from "./data/Locale";

type IntlManagerProps = {
  children: React.ReactNode;
};

type IntlManagerContext = {
  locale?: Locale | null;
  messages?: MessageDescriptor;
  setLocale: (locale: Locale) => void;
};

type Messages = Record<string, string>;

export const IntlManagerContext = React.createContext<IntlManagerContext>({
  setLocale: () => {},
});

export const IntlManager: React.FC<IntlManagerProps> = ({ children }) => {
  const [currentMessages, setCurrentMessages] = useState<Messages>({});

  useEffect(() => {
    const fetchMessages = async () => {
      const storedLocale = LocalStorageService.get<Locale>(StorageKey.Locale);
      const navigatorLocale = navigator.language.slice(0, 2) as Locale;

      if (!storedLocale) return;

      const fetchMessagesFunc =
        messages[storedLocale] ||
        messages[navigatorLocale] ||
        messages[defaultLocale];

      setCurrentMessages((await fetchMessagesFunc()).default);
    };

    fetchMessages();
  }, [setCurrentMessages]);

  const setLocale = useCallback(
    (locale: Locale) =>
      LocalStorageService.set<Locale>(StorageKey.Locale, locale),
    []
  );

  if (currentMessages == null) {
    return <SplashScreen />;
  }

  const locale = LocalStorageService.get<Locale>(StorageKey.Locale);

  return (
    <IntlManagerContext.Provider
      value={{
        locale,
        setLocale,
        messages: currentMessages,
      }}
    >
      <IntlProvider
        locale={locale as string}
        defaultLocale={defaultLocale}
        messages={currentMessages}
      >
        {children}
      </IntlProvider>
    </IntlManagerContext.Provider>
  );
};

export default IntlManager;
