import { Observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { RoutePath } from "src/data/RoutePath";
import { useLogin } from "src/hooks/useLogin";

const Page = React.lazy(() => import("src/components/pages/Page"));
const NotFoundError = React.lazy(
  () => import("src/components/pages/NotFoundError")
);

const Routes: React.FC = () => {
  const history = useHistory();
  const { loginStore } = useLogin();

  useEffect(() => {
    (async () => {
      if (!window.location.pathname.includes(RoutePath.LoginCallback)) {
        await loginStore.tryLogin();
      } else {
        await loginStore.handleLogin((pathName) => history.push(pathName));
      }
    })();
  }, []);

  return (
    <Switch>
      <Route path={RoutePath.All} exact>
        <Observer>
          {() => (loginStore.user ? <Page /> : <>Loading user...</>)}
        </Observer>
      </Route>
      <Route path={RoutePath.All} component={NotFoundError} />
    </Switch>
  );
};

export default Routes;
