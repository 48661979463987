export interface ILocalStorageService {
  get: <T>(key: string) => T | null;
  set: <T>(key: string, value: T) => void;
}

class LocalStorageService implements ILocalStorageService {
  private getItem(key: string) {
    return localStorage.getItem(key);
  }

  private setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  public get<T>(key: string): T | null {
    const value = this.getItem(key);

    if (!value) return null;

    return JSON.parse(value) as T;
  }

  public set<T>(key: string, value: T) {
    const stringifiedValue = JSON.stringify(value);

    this.setItem(key, stringifiedValue);
  }
}

export default new LocalStorageService();
