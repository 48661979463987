import "fontsource-roboto";
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { IntlManager } from "src/components/context/Intl";
import LoginManager from "src/components/context/LoginManager";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../../../data/theme/theme";
import Routes from "./Routes";
import { GlobalStyle } from "./styled";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Router>
        <IntlManager>
          <LoginManager>
            <GlobalStyle gridSize={10} />
            <Suspense fallback={<>Loading page...</>}>
              <Routes />
            </Suspense>
          </LoginManager>
        </IntlManager>
      </Router>
    </ThemeProvider>
  );
};

export default App;
