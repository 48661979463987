import React from "react";
import { Background } from "./styled";


export const SplashScreen: React.FC = () => (
  <Background>
    Loading page...
  </Background>
);

export default SplashScreen;
