import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyA_dIY1Sk5MUvwuqmuDADK_Own5Dls0PXE",
  authDomain: "nedair-unitforms.firebaseapp.com",
  projectId: "nedair-unitforms",
  storageBucket: "nedair-unitforms.appspot.com",
  messagingSenderId: "15977820048",
  appId: "1:15977820048:web:6f7039bb2f7dea05ed4d15",
  measurementId: "G-SR1Z9N20H0",
};

firebase.initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "development") {
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.functions().useEmulator("localhost", 5001);
  firebase.storage().useEmulator("localhost", 9199);
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.firestore().settings({
    host: "localhost:8080",
    ssl: false,
    experimentalForceLongPolling: true,
  });
}

const db = firebase.firestore();
export const auth = firebase.auth();

export default db;
